@use 'sass:map';

$font-family: 'Inter', sans-serif;
$root-font-size: 62.5%; /* 62.5% of 16px browser font size is 10px */
$base-font-size: 1.4rem; /* 1.6 * 10px = 16px */

$font-sizes: (
	'xxxs': $root-font-size,
	'xxs': 1.2rem,
	'xs': $base-font-size,
	's': 1.6rem,
	'm': 1.8rem,
	'l': 2rem,
	'xl': 2.4rem,
	'xxl': 3.2rem,
	'xxxl': 4rem,
);

$font-weights: (
	'extra-light': 200,
	'light': 300,
	'regular': 400,
	'medium': 500,
	'semi-bold': 600,
	'bold': 700,
	'extra-bold': 800,
	'black': 900,
);

$line-heights: (
	'xxxs': 1.4rem,
	'xxs': 1.6rem,
	'xs': 2.4rem,
	's': 2.4rem,
	'm': 2.4rem,
	'l': 3.2rem,
	'xl': 3.2rem,
	'xxl': 4.2rem,
	'xxxl': 5rem,
);

$font-face-src:
	url('/libs/common/assets/src/lib/fonts/variable/Inter-VariableFont_slnt,wght.woff2') format('woff2-variations'),
	url('/libs/common/assets/src/lib/fonts/variable/Inter-VariableFont_slnt,wght.woff2') format('woff2'),
	url('/libs/common/assets/src/lib/fonts/variable/Inter-VariableFont_slnt,wght.ttf') format('truetype');

@function oper-font-size($size-key) {
	$font-size: map-get($font-sizes, $size-key);

	@if $font-size == null {
		@error 'Font size was not found in $font-sizes map by passed $size-key parameter -> #{$size-key};';
	}

	@return $font-size;
}

@function oper-font-weight($weight-key) {
	$font-weight: map-get($font-weights, $weight-key);

	@if $font-weight == null {
		@error 'Font weight was not found in $font-weights map by passed $weight-key parameter -> #{$weight-key};';
	}

	@return $font-weight;
}

@function oper-line-height($line-height-key) {
	$line-height: map-get($line-heights, $line-height-key);

	@if $line-height == null {
		@error 'Line height was not found in $line-heights map by passed $line-height-key parameter -> #{$line-height-key};';
	}

	@return $line-height;
}

@mixin set-typography($size-key: 'xs', $weight-key: 'regular') {
	font-size: oper-font-size($size-key);
	line-height: oper-line-height($size-key);
	font-weight: oper-font-weight($weight-key);
}

// Extra light
@font-face {
	font-family: $font-family;
	src: $font-face-src;
	font-variation-settings: 'wght' oper-font-weight('extra-light');
}

// Light
@font-face {
	font-family: $font-family;
	src: $font-face-src;
	font-variation-settings: 'wght' oper-font-weight('light');
}

// Regular
@font-face {
	font-family: $font-family;
	src: $font-face-src;
	font-variation-settings: 'wght' oper-font-weight('regular');
}

// Medium
@font-face {
	font-family: $font-family;
	src: $font-face-src;
	font-variation-settings: 'wght' oper-font-weight('medium');
}

// Semi-Bold
@font-face {
	font-family: $font-family;
	src: $font-face-src;
	font-variation-settings: 'wght' oper-font-weight('semi-bold');
}

// Bold
@font-face {
	font-family: $font-family;
	src: $font-face-src;
	font-variation-settings: 'wght' oper-font-weight('bold');
}

// Extra Bold
@font-face {
	font-family: $font-family;
	src: $font-face-src;
	font-variation-settings: 'wght' oper-font-weight('extra-bold');
}

// Black
@font-face {
	font-family: $font-family;
	src: $font-face-src;
	font-variation-settings: 'wght' oper-font-weight('black');
}
