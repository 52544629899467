@import 'variables';

/** Maintains spacing consistency */
@function spacing($number) {
	@return $number * $size-spacing;
}

/** Mixes a color with white */
@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

/** Mixes a color with black */
@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

@function transparency($color, $value) {
	@return rgba($color, $value);
}
