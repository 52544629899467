@import '@angular/material/prebuilt-themes/indigo-pink.css';

.mat-mdc-table {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-mdc-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-mdc-cell,
.mat-mdc-footer-cell {
  font-size: 14px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-input-element::placeholder,
.mat-datepicker-input::placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-mdc-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-mdc-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-mdc-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-mdc-menu-item {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button {
  color: inherit;
  background: transparent;
}

.mat-mdc-table {
  background: #fff;
}

.mat-mdc-table thead,
.mat-table tbody,
.mat-table tfoot,
mat-header-row,
mat-row,
mat-footer-row,
[mat-header-row],
[mat-row],
[mat-footer-row],
.mat-mdc-table-sticky {
  background: inherit;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
  line-height: normal;
  letter-spacing: normal;
}

.mat-mdc-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-cell,
.mat-mdc-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-menu-panel {
  background: #fff;
}

.mat-mdc-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.06);
}
