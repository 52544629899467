@import 'variables';
@import 'functions';

.oper-client-select-user {
	// Default state
	&.ng-select:not(.ng-select-disabled) {
		border-radius: spacing(2);

		.ng-select-container {
			border-radius: spacing(2);
			border: 0.1rem solid $color-white;
			column-gap: spacing(2);
			box-shadow: none;
		}

		.ng-value-container .ng-input > input {
			cursor: pointer;
		}

		.ng-select-container:hover {
			border: 0.1rem solid $color-gray-200;
			cursor: pointer;

			.ng-clear-wrapper {
				display: block;
				text-align: center;
			}

			.ng-arrow-wrapper {
				display: block;
			}
		}

		.ng-clear-wrapper {
			display: none;
		}

		.ng-arrow-wrapper {
			display: none;
		}

		.ng-dropdown-panel {
			padding: spacing(1) 0;
			background-color: $color-white;
			border: 0.1rem solid $color-gray-200;
			box-shadow: 0px 4px 23px 2px rgba(0, 0, 0, 0.08);
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
			background-color: $color-gray-100;
			color: $color-gray-700;
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
			background-color: $color-gray-200;
			color: $color-black;
		}
	}

	// Dropdown opened state
	&.ng-select.ng-select-opened {
		.ng-select-container {
			border: 0.1rem solid $color-primary;
			box-shadow: none;

			.ng-clear-wrapper {
				display: block;
				text-align: center;
			}

			.ng-arrow-wrapper {
				display: block;
			}
		}
	}

	// Focused state
	&.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
		border: 0.1rem solid $color-primary;
		box-shadow: none;

		.ng-clear-wrapper {
			display: block;
			text-align: center;
		}

		.ng-arrow-wrapper {
			display: block;
		}
	}

	// Disabled state
	&.ng-select.ng-select-disabled {
		.ng-clear-wrapper {
			display: none;
		}

		.ng-arrow-wrapper {
			display: none;
		}

		.ng-select-container {
			background-color: transparent;
			border: 0.1rem solid transparent;
			border-radius: spacing(2);
		}

		.ng-select-container:hover {
			background-color: $color-gray-100;
			border: 0.1rem solid $color-gray-200;
			border-radius: spacing(2);
			box-shadow: none;
			cursor: not-allowed;
		}

		.ng-value-container .ng-input > input {
			cursor: not-allowed;
		}
	}
}

.oper-client-async-select {
	&.ng-select {
		min-width: 0;
	}

	&.ng-select:not(.ng-select-disabled) {
		border-radius: spacing(2);
		position: initial;

		.ng-select-container {
			border: none;
			display: inline-flex;
			box-shadow: none;
			min-width: 0;

			& .ng-input {
				padding-left: 0 !important;
			}

			&.ng-has-value .ng-value-container {
				padding-left: 0;
			}
		}
	}

	// hide arrow option
	&.hide-arrow {
		.ng-arrow-wrapper {
			display: none;
		}
	}

	// Focused state
	&.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
		border: none;
		border-radius: spacing(0.5);
		box-shadow: none;
	}

	&.ng-select.ng-select-opened {
		.ng-clear-wrapper {
			display: block;
			text-align: center;
		}

		.ng-dropdown-panel {
			box-shadow: 0px 4px 23px 2px rgba($color-black, 0.08);
			border: none;
			margin-top: spacing(0.675);
			border-radius: spacing(1);
			overflow: scroll;
		}
	}

	.ng-dropdown-panel {
		left: -0.1rem;
		right: -0.1rem;
		width: unset;
	}
}
