@import 'variables';
@import 'functions';
@import 'fonts';
@import 'stacking-orders';
@import 'tooltip';
@import 'ng-select-overrides';

* {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

a {
	color: inherit;
}

html {
	font-size: $root-font-size;
	height: 100%;
}

body {
	font-family: $font-family;
	@include set-typography('xs');
	display: flex;
	width: 100%;
	min-height: 100%;
	color: $color-black;
	background: var(--color-background);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
}

.pac-container {
	z-index: oper-stacking-order('sticky');
	position: fixed !important;
}

.beamer_show .beamer_beamer.left {
	left: $width-sidebar !important;
	@media (max-width: 800px) {
		width: calc(100% - $width-sidebar) !important;
	}
}

.beamer_noscroll {
	overflow: visible !important;
}

.side-bar-collapsed .beamer_show .beamer_beamer.left {
	left: $width-sidebar--collapsed !important;
	@media (max-width: 800px) {
		width: calc(100% - $width-sidebar--collapsed) !important;
	}
}

.oper-link {
	color: var(--color-primary);
	cursor: pointer;

	&__underline {
		text-decoration: underline;
	}
}

.highlighted {
	color: var(--color-primary);
	font-style: normal;
}

.font {
	&--xl {
		@include set-typography('xl');
	}

	&--l {
		@include set-typography('l');
	}

	&--m {
		@include set-typography('m');
	}

	&--s {
		@include set-typography('s');
	}

	&--xs {
		@include set-typography('xs');
	}

	&--xxs {
		@include set-typography('xxs');
	}

	&--xxxs {
		@include set-typography('xxxs');
	}

	&--black {
		font-weight: oper-font-weight('black');
	}

	&--extra-bold {
		font-weight: oper-font-weight('extra-bold');
	}

	&--bold {
		font-weight: oper-font-weight('bold');
	}

	&--semi-bold {
		font-weight: oper-font-weight('semi-bold');
	}

	&--medium {
		font-weight: oper-font-weight('medium');
	}

	&--regular {
		font-weight: oper-font-weight('regular');
	}

	&--light {
		font-weight: oper-font-weight('light');
	}

	&--extra-light {
		font-weight: oper-font-weight('extra-light');
	}
}

.typography {
	&--xxl {
		@include set-typography('xxl', 'bold');
	}

	&--xl {
		@include set-typography('xl', 'bold');
	}

	&--l {
		@include set-typography('l', 'bold');
	}

	&--m {
		@include set-typography('m', 'semi-bold');
	}

	&--s {
		@include set-typography('s', 'semi-bold');
	}

	&--xs-semi-bold {
		@include set-typography('xs', 'semi-bold');
	}

	&--xs {
		@include set-typography('xs', 'regular');
	}

	&--xxs {
		@include set-typography('xxs', 'medium');
	}
}

.color {
	&--gray-400 {
		color: $color-gray-400;
	}
}
