.tooltip {
	@extend .oper-tooltip;
}

.cdk-global-scrollblock {
	.oper-tooltip {
		position: fixed !important;
	}
}

.oper-tooltip {
	padding: spacing(4) !important;
	border-radius: $size-border-radius-small !important;
	background: var(--tooltip-panel-background) !important;
	box-shadow: $box-shadow-extra !important;
	color: var(--tooltip-panel-text) !important;
	text-align: left !important;
	width: 100%;
	z-index: oper-stacking-order('tooltip');

	&.tooltip-left {
		&::after {
			border-color: transparent transparent transparent var(--tooltip-panel-background) !important;
		}
	}

	&.tooltip-top {
		&::after {
			border-color: var(--tooltip-panel-background) transparent transparent !important;
		}
	}

	&.tooltip-right {
		&::after {
			border-color: transparent var(--tooltip-panel-background) transparent transparent !important;
		}
	}

	&.tooltip-bottom {
		&::after {
			border-color: transparent transparent var(--tooltip-panel-background) !important;
		}
	}

	&.--no-arrow {
		&::after {
			border-style: hidden !important;
		}
	}

	&.tooltip-break-word {
		word-wrap: break-word;

		&.text-center {
			text-align: center !important;
		}
		&--extended {
			max-width: 100%;
			width: auto;
		}
	}
}
